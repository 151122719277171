<template>
    <div class="invoices-container">
        <el-row :gutter="12">
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="loading">
                    <div
                        v-if="invoices.length"
                        class="key-value"
                        :class="{selected: filter === null}"
                        @click="filter = null"
                    >
                        <label>Total invoiced <span>(ex. GST)</span></label>
                        <span>{{ total_invoiced | currency }}</span>
                    </div>

                    <el-alert
                        v-else
                        description="No invoices available."
                        :closable="false"
                    />
                </widget-card>
            </el-col>
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="loading">
                    <div
                        v-if="invoices.length"
                        class="key-value"
                        :class="{selected: filter === 'paid'}"
                        @click="filter = 'paid'"
                    >
                        <label>Total paid <span>(ex. GST)</span></label>
                        <span>{{ total_paid | currency }}</span>
                    </div>

                    <el-alert
                        v-else
                        description="No invoices available."
                        :closable="false"
                    />
                </widget-card>
            </el-col>
            <el-col :lg="8" :sm="24">
                <widget-card style="margin-bottom: 10px;" :loading="loading">
                    <div
                        v-if="invoices.length"
                        class="key-value"
                        :class="{selected: filter === 'due'}"
                        @click="filter = 'due'"
                    >
                        <label>Total due <span>(inc. GST)</span></label>
                        <span>{{ total_due | currency }}</span>
                    </div>

                    <el-alert
                        v-else
                        description="No invoices available."
                        :closable="false"
                    />
                </widget-card>
            </el-col>
        </el-row>

        <ul v-if="sorted_invoices.length" class="invoices-list">
            <invoice-block
                v-for="invoice in sorted_invoices"
                :key="invoice.id"
                :invoice="invoice"
            />
        </ul>
        <el-alert
            v-else-if="loading"
            description="Loading invoices."
            :closable="false"
        />
        <el-alert
            v-else
            description="No invoices for this filter."
            :closable="false"
        />
    </div>
</template>

<script>
import InvoiceBlock from '@/components/blocks/InvoiceBlock';
import WidgetCard from '@/components/generic/WidgetCard';

import invoiceMixin from '@/mixins/invoice.mixin';

export default {
    name: 'project-invoices',
    components: {
        InvoiceBlock,
        WidgetCard,
    },
    mixins: [invoiceMixin],
    props: {
        project: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            loading: true,
            line_items: [],
            filter: null,
        };
    },
    computed: {
        invoices() {
            if (this.loading) return [];
            var seen = [];
            return this.line_items
                .map((line_item) => line_item.invoice)
                .filter((invoice) => {
                    if (seen.includes(invoice.id)) return false;
                    else {
                        seen.push(invoice.id);
                        return true;
                    }
                });
        },
        total_invoiced() {
            return this.line_items.reduce(
                (sum, line_item) => sum + line_item.line_amount,
                0
            );
        },
        total_paid() {
            const paid_invoices_ids = this.invoices
                .filter((i) => {
                    return !!i.fully_paid_on_date;
                })
                .map((i) => i.id);

            return this.line_items
                .filter((line_item) =>
                    paid_invoices_ids.includes(line_item.invoice.id)
                )
                .reduce((sum, line_item) => sum + line_item.line_amount, 0);
        },
        total_due() {
            const unpaid_invoices_ids = this.invoices
                .filter((i) => {
                    return i.fully_paid_on_date === null;
                })
                .map((i) => i.id);

            return this.line_items
                .filter((line_item) =>
                    unpaid_invoices_ids.includes(line_item.invoice.id)
                )
                .reduce((sum, line_item) => sum + line_item.line_amount, 0);
        },
        sorted_invoices() {
            if (!this.invoices) return [];
            return this.invoices
                .slice(0)
                .filter((i) => {
                    switch (this.filter) {
                        case 'due':
                            return i.fully_paid_on_date === null;
                        case 'paid':
                            return !!i.fully_paid_on_date;
                        default:
                            return true;
                    }
                })
                .sort((a, b) => {
                    if (a.invoice_number < b.invoice_number) return 1;
                    if (a.invoice_number > b.invoice_number) return -1;
                    return 0;
                });
        },
    },
    mounted() {
        this.$bind(
            'line_items',
            this.$fire
                .collection('line_items')
                .where(
                    'project',
                    '==',
                    this.$fire.doc(`projects/${this.project.id}`)
                ),
            {maxRefDepth: 1}
        ).finally(() => {
            this.loading = false;
        });
    },
};
</script>

<style lang="scss" scoped>
.invoices-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;

    ::v-deep .el-card .el-card__body label span {
        font-size: 8px;
        opacity: 0.8;
    }

    ::v-deep .key-value {
        cursor: pointer;
    }

    .invoices-list {
        padding: 0;
        list-style: none;
    }
}
</style>
