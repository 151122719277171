<template>
    <li
        :class="{paid: is_paid}"
        @click.exact="navigateToInvoice(invoice)"
        @click.meta.exact="navigateToInvoice(invoice, true)"
    >
        <el-row :gutter="12">
            <el-col :lg="16">
                <div class="row">
                    <el-tag
                        :type="
                            is_new ? 'danger' : is_updated ? 'default' : 'info'
                        "
                        :disable-transitions="true"
                        size="small"
                        class="number"
                    >
                        {{ invoice.invoice_number }}
                    </el-tag>

                    <span class="ref">
                        <template v-if="invoice.reference">
                            <span class="ref__slash">/</span>
                            <strong>{{ invoice.reference }}</strong>
                        </template>
                    </span>

                    <span class="date">
                        {{ invoice.date | dateformat('DD/MM/YYYY') }}
                    </span>

                    <span class="total">
                        {{ invoice.sub_total | currency }}
                        <span class="small">(ex. GST)</span>
                    </span>
                </div>
            </el-col>

            <el-col :lg="8">
                <div class="row right">
                    <span class="due">
                        <template v-if="is_paid">
                            Paid:
                            {{
                                invoice.fully_paid_on_date
                                    | dateformat('DD/MM/YYYY')
                            }}
                        </template>
                        <template v-else-if="invoice.due_date">
                            Due:
                            {{ invoice.due_date | dateformat('DD/MM/YYYY') }}
                        </template>
                    </span>

                    <div
                        class="row tooltip-container"
                        style="flex: 1; justify-content: flex-end;"
                    >
                        <el-tooltip
                            v-if="!is_linked"
                            :open-delay="200"
                            content="Needs linking"
                            placement="top"
                        >
                            <div class="badge">
                                <svgicon
                                    name="invoice_linking"
                                    class="icon red"
                                />
                            </div>
                        </el-tooltip>
                        <el-tooltip
                            v-if="is_sent"
                            :open-delay="200"
                            content="Sent to client"
                            placement="top"
                        >
                            <div class="badge">
                                <svgicon name="invoice_sent" class="icon" />
                            </div>
                        </el-tooltip>
                        <el-tooltip
                            content="Open in XERO"
                            placement="top"
                            :open-delay="200"
                        >
                            <div class="badge">
                                <svgicon name="invoice_share" class="icon" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </el-col>
        </el-row>
    </li>
</template>

<script>
import moment from 'moment';

export default {
    name: 'invoice-block',
    props: {
        invoice: {
            type: Object,
            required: true,
        },
    },
    computed: {
        is_new() {
            if (!this.invoice.created_at) return false;
            return (
                moment().diff(
                    this.$options.filters.tstamp2moment(
                        this.invoice.created_at
                    ),
                    'minutes'
                ) < 5
            );
        },
        is_updated() {
            if (!this.invoice.updated_at) return false;
            return (
                moment().diff(
                    this.$options.filters.tstamp2moment(
                        this.invoice.updated_at
                    ),
                    'minutes'
                ) < 2
            );
        },
        is_sent() {
            return !!this.invoice.sent_to_contact;
        },
        is_paid() {
            return !!this.invoice.fully_paid_on_date;
        },
        is_linked() {
            if (this.invoice.is_linked === undefined) return false;
            return !!this.invoice.is_linked;
        },
    },
    methods: {
        navigateToInvoice(invoice, newTab = false) {
            const data = {
                name: 'management_invoices_detail',
                params: {
                    invoice_number: invoice.invoice_number,
                },
            };

            if (newTab) {
                let routeData = this.$router.resolve(data);
                window.open(routeData.href, '_blank');
            } else {
                this.$router.push(data).catch((err) => {});
            }
        },
    },
};
</script>

<style lang="scss" scoped>
li {
    position: relative;
    display: grid;
    width: 100%;
    padding: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid $border-grey;
    cursor: pointer;
    background-color: $white;
    box-sizing: border-box;
    user-select: none;

    &.paid {
        opacity: 0.5;
    }

    &:nth-child(3n + 3) {
        margin-right: 0;
    }

    &:hover {
        box-shadow: 0 0 0 1px $blue;
        border: 1px solid $blue;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 1199px) {
            &.right {
                margin-top: 10px;
            }
        }

        @media screen and (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;

            & > span {
                width: 100% !important;
                text-align: left !important;
                margin-bottom: 10px;
            }
        }
    }

    .el-tag.number {
        width: 100px;
        text-align: center;
    }
    span.ref {
        margin-left: 6px;
        font-size: 12px;
        flex: 1;

        &__slash {
            padding-right: 5px;
        }

        @media screen and (max-width: 992px) {
            margin-left: 0 !important;

            &__slash {
                display: none;
            }
        }
    }
    span.date {
        margin-left: 15px;
        padding-left: 15px;
        border-left: 1px solid $border-grey-light;
        font-size: 10px;

        @media screen and (max-width: 1199px) {
            flex: 1;
            text-align: right;
        }

        @media screen and (max-width: 992px) {
            border-left: 0 !important;
            padding-left: 0 !important;
            margin-left: 0 !important;
        }
    }
    span.due {
        padding-left: 15px;
        border-left: 1px solid $border-grey-light;
        font-size: 10px;

        @media screen and (max-width: 992px) {
            border-left: 0 !important;
            padding-left: 0 !important;
        }
    }
    span.total {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-left: 15px;
        padding-left: 15px;
        border-left: 1px solid $border-grey-light;
        font-size: 15px;
        width: 120px;

        .small {
            font-size: 8px;
            margin-left: 5px;
        }

        @media screen and (max-width: 1199px) {
            text-align: right;
        }

        @media screen and (max-width: 992px) {
            border-left: 0 !important;
            padding-left: 0 !important;
            margin-left: 0 !important;
        }
    }

    .tooltip-container {
        @media screen and (max-width: 992px) {
            flex-direction: row !important;
        }
    }

    .badge {
        background-color: white;
        box-shadow: 0 0 0 3px white;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 2px;

        .icon {
            width: 25px;
            height: 25px;

            &.red {
                fill: $blue;
            }
        }
    }
}
</style>
