<template>
    <div class="outer">
        <breadcrumb-header
            v-if="project"
            :labels="[project.name]"
            go_back_to="management_projects"
        />

        <div class="inner">
            <content-block title="Overview">
                <el-alert :closable="false">
                    <!-- TODO: Create something here -->
                    Projects stats and progress will be added here.
                </el-alert>
            </content-block>

            <!--        <content-block title="Access cards">-->
            <!--            <div slot="right" class="right">-->
            <!--                <div-->
            <!--                    v-loading="access_cards.loading"-->
            <!--                    class="spinner"-->
            <!--                    element-loading-spinner="el-icon-loading"-->
            <!--                    element-loading-background="rgba(0, 0, 0, 0)"-->
            <!--                />-->
            <!--                <el-button size="mini" @click="addAccessCard">-->
            <!--                    New-->
            <!--                </el-button>-->
            <!--            </div>-->
            <!--            <div v-if="access_cards.loading" class="message">-->
            <!--                <span>-->
            <!--                    Fetching access cards.-->
            <!--                </span>-->
            <!--            </div>-->
            <!--            <div v-else-if="access_cards.items.length">-->
            <!--                <div-->
            <!--                    v-for="card in access_cards.items"-->
            <!--                    :key="card.id"-->
            <!--                    class="access-card"-->
            <!--                >-->
            <!--                    <el-row :gutter="12">-->
            <!--                        <el-col-->
            <!--                            :sm="12"-->
            <!--                            style="display: flex; flex-direction: column;"-->
            <!--                        >-->
            <!--                            <div class="item">-->
            <!--                                <label>Valid for:</label>-->
            <!--                                <el-tag-->
            <!--                                    :disable-transitions="true"-->
            <!--                                    size="small"-->
            <!--                                    type="info"-->
            <!--                                >-->
            <!--                                    {{ card.expiry | tstamp2moment | daysDiff }}-->
            <!--                                    {{ 'day' | pluralize(2) }}-->
            <!--                                </el-tag>-->
            <!--                            </div>-->
            <!--                            <div class="item">-->
            <!--                                <label>Permission:</label>-->
            <!--                                <el-tag-->
            <!--                                    :disable-transitions="true"-->
            <!--                                    size="small"-->
            <!--                                    type="info"-->
            <!--                                >-->
            <!--                                    {{ card.permission }}-->
            <!--                                </el-tag>-->
            <!--                            </div>-->
            <!--                            <div class="item">-->
            <!--                                <label>Passcode:</label>-->
            <!--                                <el-tag-->
            <!--                                    :disable-transitions="true"-->
            <!--                                    size="small"-->
            <!--                                    type="info"-->
            <!--                                >-->
            <!--                                    {{ card.passcode }}-->
            <!--                                </el-tag>-->
            <!--                            </div>-->
            <!--                        </el-col>-->
            <!--                        <el-col :sm="12" style="text-align: right;">-->
            <!--                            <el-button-->
            <!--                                size="mini"-->
            <!--                                @click="viewAccessCard(card)"-->
            <!--                            >-->
            <!--                                View-->
            <!--                            </el-button>-->
            <!--                            <el-button-->
            <!--                                type="danger"-->
            <!--                                size="mini"-->
            <!--                                @click="deleteAccessCard(card)"-->
            <!--                            >-->
            <!--                                <i class="el-icon-delete" />-->
            <!--                            </el-button>-->
            <!--                        </el-col>-->
            <!--                    </el-row>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <div v-else class="message">-->
            <!--                <span>-->
            <!--                    <b>{{ client.name }}</b> won't be able to access-->
            <!--                    <b>{{ project.name }}</b>-->
            <!--                </span>-->
            <!--            </div>-->
            <!--        </content-block>-->

            <content-block
                v-if="project && $store.getters.is_xero_enabled"
                title="Invoices"
            >
                <project-invoices :project="project" />
            </content-block>
        </div>
    </div>
</template>

<script>
import ContentBlock from '@/components/blocks/ContentBlock';

import BreadcrumbHeader from '@/pages/management/components/BreadcrumbHeader';
import ProjectInvoices from '@/pages/management/projects/components/ProjectInvoices';

export default {
    name: 'management-project-detail',
    components: {
        ContentBlock,

        ProjectInvoices,
        BreadcrumbHeader,
    },
    data() {
        return {
            filter: null,
            access_cards: {
                items: [],
                loading: false,
            },
            customColors: [
                {color: '#f56c6c', percentage: 30},
                {color: '#FFFFFF', percentage: 100},
                {color: '#90d050', percentage: 100},
            ],
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
    },
    mounted() {
        if (!this.project) {
            this.$router.replace({name: 'management_projects'});
            return;
        }

        // this.fetchAccessCards();
    },
    methods: {
        // fetchAccessCards() {
        //     if (
        //         !this.project ||
        //         !this.client ||
        //         this.access_cards.loading ||
        //         this.access_cards.items.length
        //     )
        //         return;
        //
        //     this.access_cards.loading = true;
        //     this.$bind(
        //         'access_cards.items',
        //         this.$fire
        //             .collection('access_cards')
        //             .where(
        //                 'project',
        //                 '==',
        //                 this.$fire.doc(`/projects/${this.project.id}`)
        //             )
        //             .where(
        //                 'client',
        //                 '==',
        //                 this.$fire.doc(`/clients/${this.client.id}`)
        //             )
        //             .orderBy('created_at', 'desc'),
        //         {maxRefDepth: 0}
        //     ).then(() => {
        //         this.access_cards.loading = false;
        //     });
        // },
        // generatePasscode() {
        //     var result = '';
        //     var chars = 'ABCDEFGHJKLMNPRSUVWXYZ';
        //     var charsLength = chars.length;
        //     for (var i = 0; i < 3; i++) {
        //         result += chars.charAt(Math.floor(Math.random() * charsLength));
        //     }
        //
        //     return (result += parseInt(Math.random() * 9999, 10));
        // },
        // addAccessCard() {
        //     this.$fire.collection('access_cards').add({
        //         project: this.$fire.doc(`projects/${this.project.id}`),
        //         client: this.$fire.doc(`clients/${this.client.id}`),
        //         created_at: moment().toDate(),
        //         enabled: true,
        //         expiry: moment().add(30, 'days').toDate(),
        //         passcode: this.generatePasscode(),
        //         permission: 'full',
        //         project_ref: this.project.ref,
        //     });
        // },
        // deleteAccessCard(card) {
        //     this.$fire.doc(`access_cards/${card.id}`).delete();
        // },
        // viewAccessCard(card) {
        //     window.open(
        //         `/access/${this.project.ref}?passcode=${card.passcode}`
        //     );
        // },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    flex: 1;
    display: flex;
    flex-direction: column;

    .inner {
        .stats {
            max-width: calc(100% - 40px);
            background: $black;
            margin: 20px;
            border-radius: 5px;
            padding: 30px;

            h3 {
                margin: 0;
                margin-bottom: 20px;
                color: white;
            }

            ::v-deep .el-progress {
                .el-progress-circle svg path.el-progress-circle__track {
                    stroke: $black-darker;
                }
                .el-progress-bar .el-progress-bar__outer {
                    background-color: $black-darker;
                }
                .el-progress__text {
                    color: white;
                }
            }

            .graphs {
                padding-top: 30px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .graph-container {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    span {
                        color: white;
                    }
                }
            }
        }

        .content-block {
            background: $white;
            margin-bottom: 0;

            .right {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                .spinner {
                    width: 28px;
                    height: 28px;
                    margin-right: 10px;
                    display: inline-block;
                }
            }

            .access-card {
                padding: 15px 20px;
                margin: 10px 20px;
                background-color: $grey;
                border: 1px solid $border-grey-light;
                border-radius: 5px;

                .item {
                    margin: 2px 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    label {
                        font-size: 14px;
                        margin-right: 5px;
                        width: 100px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
</style>
